import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { Layout } from '../components/Layout'
import WPGBlocks from 'react-gutenberg'
import { Box, Heading, Paragraph, Button } from 'grommet'
import GetCustomBlock from '../blocks'
import CustomFieldBlocks from '../components/CustomBlocks'
import { GatsbyImage } from 'gatsby-plugin-image'

export const BlogPostTemplate = ({
  title,
  content,
  blocks,
  date,
  featuredImage,
}) => {
  const postImage = {
    data: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredImage?.node?.alt || ``,
  }

  console.log('postImage', postImage)

  return (
    <Box align="center">
      <header>
        <Paragraph
          textAlign="center"
          size={'medium'}
          margin={{ vertical: 'small' }}
          color={'black'}
          style={{ fontWeight: 'bold' }}
        >
          {date}
        </Paragraph>

        <Heading
          size="medium"
          level={2}
          color={'brand'}
          textAlign="center"
          margin={{ bottom: 'medium', top: 'none' }}
        >
          {title}
        </Heading>
        <Box
          margin={{ bottom: 'medium', horizontal: 'auto' }}
          alignSelf="center"
          width="50%"
          border={{ color: 'black', bottom: { size: '1px' } }}
        />
      </header>

      {postImage?.data && (
        <GatsbyImage
          image={postImage.data}
          alt={postImage.alt}
          style={{
            width :"100%",
            marginBottom: 50,
            maxWidth: 700,
            margin: 'auto',
          }}
        />
      )}

      <Box pad={{ horizontal: 'large' }} alignContent="center">
        {blocks && (
          <WPGBlocks
            blocks={blocks}
            mapToBlock={name => GetCustomBlock(name, content)}
          />
        )}
      </Box>
    </Box>
  )
}

const BlogPost = ({ data }) => {
  const { wpPost: post } = data

  return (
    <Layout>
      <Helmet title={`${post.title} | Blog`} />
      <BlogPostTemplate
        title={post.title}
        content={post.content}
        blocks={post.blocks}
        date={post.date}
        featuredImage={post.featuredImage}
      />
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      slug
      content
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
      blocks {
        attributesJSON
        blockName: name
        innerHTML: saveContent
        dynamicContent
        innerBlocks {
          attributesJSON
          blockName: name
          innerHTML: saveContent
          dynamicContent
          # attrs {
          #   blockId
          #   blockUniqueClass
          #   custom_name
          # }
          innerBlocks {
            attributesJSON
            blockName: name
            innerHTML: saveContent
            dynamicContent
            # attrs {
            #   blockId
            #   blockUniqueClass
            #   custom_name_in_column
            # }
          }
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
      tags {
        nodes {
          name
          slug
        }
      }
      author {
        node {
          name
          slug
        }
      }
    }
  }
`
